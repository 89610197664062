<template>
  <div class="clearfix">
    <b-table striped hover :items="logsForTable"></b-table>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import config from '@/config.js'
  import moment from 'moment'

  export default {
    name: 'ChangeLogsTable',
    props: ['objectType', 'objectId'],
    data(){
      return {
        logs: [],
        config: config
      }
    },
    mounted(){
      if (config.editable) {
        this.$store.dispatch('loadChangeLogs', {
          objectType: this.objectType,
          objectId: this.objectId
        }).then((response) => {
          this.logs = response.data
        })
      }
    },
    computed: {
      logsForTable(){
        return this.logs.map(log => {
          return {
            'timestamp': moment(log.timestamp).format('DD.MM.YYYY HH:mm:ss'),
            'user': log.username,
            'log': log.log
          }
        })
      }
    }
  }
</script>
